
import './App.css';
import HeaderMain from './components/header/HeaderMain';
import HeaderNav from './components/header/HeaderNav';
import Section from './components/main/Section';
import Footer from './components/Footer/Footer'
import {AiFillPhone} from 'react-icons/ai'
import {FaArrowCircleUp} from 'react-icons/fa'
import {useState, useEffect} from 'react'

function App() {
  useEffect(() => {

    window.addEventListener('scroll', handle2)

}, [])
  const [nav, setNav] = useState(false);
  const handle2 = () => {
    if (window.scrollY > 100) {
        setNav(true)
    }
    else {
        setNav(false)
    }
}



const gototop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    })
}



const gototopStyle = {
    display: nav ? 'block' : 'none',
    width: '50px',
    height: '50px',
    position: 'fixed',
    bottom: '25px',
    borderRadius: '25px',
    right: '25px',
    zIndex: '1',
    background: 'white',
    color: '#bd1a8c',
    cursor: 'pointer'
}
  return (
    <div className="App">
      
      <HeaderMain/>
      <Section/>
      <Footer />
      
      <a target='_blank' rel="noreferrer"  href={`tel: ${+998996635179}`}>
              <AiFillPhone
                fontSize='40px'
                color='#bd1a8c'
                className='phone_icon'
              />
            </a>
            <FaArrowCircleUp style={gototopStyle} className="gototop" onClick={gototop} />
            <HeaderNav/>
    </div>
  );
}

export default App;
